import React from 'react';
import { Flex, Image, Text } from 'rebass';

import { PageSection } from '../../../../components/layout/_/PageSection/PageSection.component';
import SectionHeader from '../../../../components/layout/SectionHeader/SectionHeader.component';
import { theme } from '../../../../layouts/main.layout';

export const Intro: React.FC = () => {
  return (
    <PageSection>
      <SectionHeader id="about" title="O meni" mt={{ _: 5, sm: 5 }} mb={{ _: 4, md: 5 }} />
      <Flex justifyContent={{ _: 'center', md: 'space-between' }} alignItems={{ _: 'center' }} flexDirection={{ _: 'column', md: 'row' }}>
        <Flex flexDirection="column" width={{ _: '100%', md: '50%' }} alignItems={{ _: 'center', md: 'start' }}>
          <Text as="h2" mb={{ _: 4, md: 5 }} color={theme.colors.primaryOrange}>
            Muški frizer KOKI
          </Text>
          <Text textAlign="justify" padding={{ _: '0px 5px' }} fontSize="17px">
            <b>Frizerski salon KOKI</b> je posvećen održavanju Vašeg izgleda na vrhunskom nivou. Osnovan 2004. godine u Trsteniku, salon je
            brzo postao prepoznatljiv po svojoj posvećenosti praćenju evropskih trendova u svetu friziranja. Koristim najkvalitetniji alat
            kako bi se osiguralo da Vaša frizura bude besprekorna. Moja specijalnost su srednje i kratke frizure sa različitim prelazima,
            kao i trimovanje brade. Verujem da kvalitetno friziranje nije samo stvar izgleda, već i samopouzdanja. Zato se trudim da moji
            klijenti izađu iz mog salona osećajući se samouvereno i spremno za sve što život može da im donese. Hvala Vam što ste izdvojili
            vreme da posetite salon i nadam se da ću uskoro imati priliku da Vam pokažem zašto je KOKI jedan od najboljih frizerskih salona
            u gradu.
          </Text>
        </Flex>
        <Flex mt={{ _: 4 }}>
          <Image src="/img/koki-salon-2.jpeg" width="550px" />
        </Flex>
      </Flex>
    </PageSection>
  );
};
