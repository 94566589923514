import React from 'react';
import { navigate } from 'gatsby';
import { Button, Flex, Image, Text } from 'rebass';
import { css } from '@emotion/core';

import { choosenHaircut } from '../../../../shared-state/main';
import { PageSection } from '../../../../components/layout/_/PageSection/PageSection.component';
import SectionHeader from '../../../../components/layout/SectionHeader/SectionHeader.component';
import { useRecoilState } from 'recoil';
import Dialog from '../../../../components/functional/SweetAlert';

const haircutsBox = css`
  background-color: #ffffff;
  margin-bottom: 25px;
  background-color: #fff;
  box-shadow: 0px 0px 30px 5px rgba(2, 2, 10, 0.05);
  border-radius: 15px;
  padding: 25px 5px 25px 5px;
  // width: calc(100% * (1 / 4) - 20px - 1px);

  @media screen and (min-width: 1315px) {
    width: calc(100% * (1 / 4) - 20px - 1px);
  }

  @media screen and (max-width: 832px) {
    margin: 0px 10px 25px 10px;
  }
`;

const priceStyle = css`
  text-align: center;
  margin-bottom: 20px;
  font-size: 25px;
  color: #cc3f0c;
  font-weight: bold;
`;

const buttonStyle = css`
  background-color: #cc3f0c;
  color: white;
  width: 175px;
  height: 45px;
  border-radius: 8px;
  margin-top: 5px;
  font-weight: bold;
  cursor: pointer;
`;

export const HaircutsSection: any = (props: any) => {
  const [haircut, setHaircut] = useRecoilState(choosenHaircut);

  const handleOnClick = (haircut: any) => {
    // Dialog.fire({
    //   title: 'Obaveštenje',
    //   html: `
    //   <div style="margin-top:24px;">
    //   <h3>Trenutno nije moguće rezervisati termin preko website-a</h3>
    //   <p style="margin-top: 24px;">Pozovi <a href="tel:381637771141">+381 63 777 1141</a> da rezervišeš svoj termin.</p>
    //   </div>
    //   `,
    //   icon: 'info',
    //   confirmButtonColor: '#3fc3ee',
    //   confirmButtonText: 'U redu',
    //   preConfirm: () => console.log('ggg'),
    // });
    setHaircut(haircut);
    navigate('rezervisi-termin');
  };

  return (
    <PageSection paddingBottom={25}>
      <SectionHeader id="pricelist" title="Frizure" mt={{ _: 5, sm: 5 }} mb={{ _: 4, md: 5 }} />

      <Flex
        flexWrap="wrap"
        flexDirection={{ _: 'column', sm: 'row', md: 'row' }}
        justifyContent={{ _: 'center', md: 'space-around' }}
        alignItems={{ _: 'center', md: 'start' }}
      >
        {props.haircuts.map((item: any) => (
          <Flex css={haircutsBox} flexDirection="column" alignItems="center" width="250px" height="350px" key={item._id}>
            <Image width="125px" src={item.iconUrl} />
            <Text as="h4" mt={4} fontSize="20px">
              {item.name}
            </Text>
            <span css={priceStyle}>{item.price}</span>
            <Button onClick={() => handleOnClick(item)} css={buttonStyle}>
              Zakaži
            </Button>
          </Flex>
        ))}
      </Flex>
    </PageSection>
  );
};
