import React, { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';

import { StandardPageLayout } from '../../layouts/StandardPage.layout';
import { Intro } from './sections/intro/Intro.section';
import { ReserveHaircut } from './sections/reserve-haircut/reserve-haircut.section';
import { HaircutsSection } from './sections/haircuts/haircuts.section';
import { Footer } from '../../components/layout/bottom/Footer/Footer.component';
import { ContactMap } from '../../components/layout/StoresMapSection/StoresMapSection';

const HomePage: React.FunctionComponent = () => {
  const [haircuts, setHaircuts] = useState(null) as any;

  useEffect(() => {
    const fetchHaircuts: () => Promise<AxiosResponse<any>> = async () => {
      const response = await axios.get('haircuts');

      return response;
    };

    fetchHaircuts().then((res: any) => {
      setHaircuts(res.data);
    });
  }, []);

  const metaOgImg = `/img/meta-img/road-assistance-meta-og-2-0-new.jpeg`;

  const meta = {
    title: 'Muški frizer Koki',
    description: 'Trstenik',
  };

  const og = {
    image: metaOgImg,
  };

  return (
    <StandardPageLayout
      mainHeading="Muški frizer - KOKI"
      subText="Trstenik"
      backgroundImage="bg/hero-image.png"
      backgroundImageMobile="bg/hero-image-mobile.png"
      buttonText="Zakaži termin"
      to="#pricelist"
      meta={meta}
      og={og}
    >
      <Intro />

      {haircuts && haircuts.length > 0 && <HaircutsSection haircuts={haircuts} />}

      <ReserveHaircut />

      <ContactMap />

      <Footer />
    </StandardPageLayout>
  );
};

export default HomePage;
