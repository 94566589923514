import React from 'react';
import { Box, Text, Flex } from 'rebass';
import { Link } from 'gatsby';
import { css } from '@emotion/core';

import { VozziPrimaryButton } from '../../../../components/layout/_/button.component';
import { PageSection } from '../../../../components/layout/_/PageSection/PageSection.component';

const badgeWrapper = css`
  a {
    margin-right: 15px;
  }
  @media screen and (max-width: 800px) {
    a {
      display: block;
      margin-right: 0;
      margin-bottom: 15px;
    }
    a:last-child {
      margin-bottom: 0;
    }
    display: 'block';
  }
`;

export const ReserveHaircut: React.FC = () => (
  <PageSection fullWidth backgroundImage="bg/reserve-hero-image-mobile.png">
    <div id="zakazi" style={{ padding: '100px 0', marginTop: '35px' }}>
      <Box flex="1 1 100%" textAlign="center">
        <Text fontSize={{ _: '30px', md: '30px' }} style={{ color: 'white', fontWeight: 600, marginBottom: 20 }}>
          Zakaži termin
        </Text>
        <Flex flexDirection={{ _: 'column', md: 'row' }} justifyContent="center" alignItems="center" height="100%">
          <Box css={badgeWrapper} width={{ md: '72%' }} py={{ _: 3, md: 0 }}>
            <Link to="#pricelist">
              <VozziPrimaryButton>Zakaži termin</VozziPrimaryButton>
            </Link>
          </Box>
        </Flex>
      </Box>
    </div>
  </PageSection>
);
